import React from 'react';
import ImagesProvider from '@/contexts/images';
import { Seo } from '@/components';
import { Root } from '@/layouts';
import Hero from '@/layouts/Funcionalidades/Hero';
import { ConhecaMais, ConhecaPlanos, Detail } from '@/layouts/Funcionalidades';
import FUNCIONALIDADES from '@/constants/funcionalidades';
import { AssinaturaEletronicaDetails } from '@/constants/pageDetails';
import { buttonEvents } from '@/constants/analytics';

const schema = {
  '@context': 'https://schema.org/',
  '@type': 'BreadcrumbList',
  itemListElement: [
    {
      '@type': 'ListItem',
      name: 'Home',
      item: 'https://www.simplesdental.com/',
      position: 1,
    },
    {
      '@type': 'ListItem',
      name: 'Assinatura Eletrônica',
      position: 2,
    },
  ],
};

const AssinaturaEletronica = () => (
  <ImagesProvider>
    <Seo
      title="Assinatura eletrônica, sua clínica sem papel"
      description="Solicite assinaturas dos pacientes em documentos diretamente no Simples Dental"
      schema={schema}
    />
    <Root
      variant="light"
      hero={
        <Hero
          title="Assinatura eletrônica Simples Dental: sua clínica sem papel"
          desc="Solicite assinaturas dos pacientes em documentos diretamente no Simples Dental"
          subtitle="Novidades"
          gaMetadata={{ buttonEvent: buttonEvents.assinaturaEletronica }}
          alt={FUNCIONALIDADES.assinaturaEletronica.name}
          img="tela-do-prontuario-digital-do-simples-dental-com-assinatura-eletronica"
          imageTitle="Tela do prontuário digital do Simples Dental com assinatura eletrônica"
          width={690}
          height={405}
          type="png"
        />
      }
    >
      {AssinaturaEletronicaDetails.map((i, index) => (
        <Detail item={i} index={index} key={index} />
      ))}

      <ConhecaPlanos
        gaMetadata={{ buttonEvent: buttonEvents.assinaturaEletronica }}
        title="Conheça os planos da Simples Dental e evolua sua clínica"
        buttonText="Assine agora!"
        showBackground
      />

      <ConhecaMais
        page={FUNCIONALIDADES.assinaturaEletronica.name}
        gaMetadata={{
          saibaMaisButtonEvent: buttonEvents.assinaturaEletronica,
        }}
        skip={[FUNCIONALIDADES.marketingVendas.name]}
      />
    </Root>
  </ImagesProvider>
);

export default AssinaturaEletronica;
